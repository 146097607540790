import React from 'react';
import { Link } from 'gatsby';

export const Header = () => {
    return (
        <header className="page-head">
            <div className="rd-navbar-wrap rd-navbar-minimal">
                <nav data-layout="rd-navbar-fixed" data-sm-layout="rd-navbar-fixed" data-md-device-layout="rd-navbar-fixed" data-lg-layout="rd-navbar-static" data-lg-device-layout="rd-navbar-static" data-stick-up-clone="false" data-md-stick-up-offset="100px" data-lg-stick-up-offset="150px" className="rd-navbar">
                    <div className="shell shell-fluid">
                        <div className="rd-navbar-inner">
                            <div className="rd-navbar-panel">
                                <button data-rd-navbar-toggle=".rd-navbar-nav-wrap" className="rd-navbar-toggle"><span></span></button>
                                <Link to="/" className="rd-navbar-brand brand header-logo-image">
                                    <img src='/images/logo.png' alt="Beauty Spot" /> 
                                </Link>
                                <ul className="social-header">
                                    <li><a href="https://instagram.com/beauty_spot_lodz" target="_blank" rel="noopener"><span className="icon icon-xxs fa fa-instagram"></span></a></li>
                                    <li><a href="https://www.facebook.com/BEAUTYSPOTLODZ/" target="_blank" rel="noopener"><span className="icon icon-xxs fa fa-facebook"></span></a></li>
                                    <li><a href="tel:+48669991866" target="_blank" rel="noopener"><span className="icon icon-xxs fa fa-phone"></span></a></li>
                                </ul>
                            </div>
                            <div className="rd-navbar-nav-wrap">
                                <ul className="rd-navbar-nav">
                                    <li>
                                        <Link to="/">
                                            Strona główna
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/services">
                                            Usługi
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/portfolio">
                                            Portfolio
                                        </Link>
                                    </li>
                                    <li><a href="https://instagram.com/beauty_spot_lodz" target="_blank" rel="noopener"><span className="icon icon-xxs fa fa-instagram"></span></a></li>
                                    <li><a href="https://www.facebook.com/BEAUTYSPOTLODZ/" target="_blank" rel="noopener"><span className="icon icon-xxs fa fa-facebook"></span></a></li>
                                    <li><a href="tel:+48669991866" target="_blank" rel="noopener"><span className="icon icon-xxs fa fa-phone"></span></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </header>
    );
};
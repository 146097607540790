import React from 'react';
import { Link } from 'gatsby';

export const Footer = () => {
    return (
        <footer className="page-foot bg-blue-dianne">
            <section className="section-sm-bottom-35">
                <div className="shell shell-fluid">
                    <div className="range range-no-gutter range-sm-center">
                        <div className="cell-xl-10">
                            <div className="range range-condensed">
                                <div className="cell-xs-12">
                                    <div className="range range-condensed range-xs-bottom text-center">
                                        <div className="cell-md-8 text-md-left">
                                            <nav className="nav-custom">
                                                <ul>
                                                    <Link to="/">
                                                        Strona główna
                                                    </Link>
                                                    <Link to="/services">
                                                        Usługi
                                                    </Link>
                                                    <Link to="/portfolio">
                                                        Portfolio
                                                    </Link>
                                                    <li>
                                                        <a href="https://instagram.com/beauty_spot_lodz" className="link-default" rel="noreferrer" target="_blank">
                                                            <span className="icon icon-sm fa fa-instagram"></span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="https://www.facebook.com/BEAUTYSPOTLODZ/" className="link-default" rel="noreferrer" target="_blank">
                                                            <span className="icon icon-sm fa fa-facebook"></span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                        <div className="cell-md-4 offset-top-30 offset-lg-top-0 text-md-right">
                                            <ul className="list-inline list-inline-sizing-1 list-inline-md">
                                                <li>Zachodnia 70, Parter, 90-403, Łódź, Śródmieście</li>
                                                <li><a href="tel:+48669991866">+ 48 669 991 866</a></li>
                                                <li><a href="mailto:contact@beauty-spot.org ">contact@beauty-spot.org </a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="cell-md-8 offset-top-26 text-md-left">
                                    <p className="rights">&nbsp;&#169;&nbsp;<span id="copyright-year"></span>&nbsp;All Rights Reserved&nbsp;<br />
                                        <a href="#">Terms of Use and Privacy Policy</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </footer>
    );
};

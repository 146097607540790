import React from 'react';
import Helmet from 'react-helmet'
import { Footer } from './footer';
import { Header } from './header';

export const Layout = ({ children, metaProps }) => {
    return (
        <div className="page">
            <Helmet {...metaProps} />
            <Header />
            <main className="page-content">
                {children}
            </main>
            <Footer />
        </div>
    );
};
